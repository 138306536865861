"use client";

import { login } from "@app/auth";
import { ROUTES } from "@app/clientOnlyRoutes";
import { config } from "@app/config";
import { DataTestIds } from "@app/utils/dataTestIds";
import { getBrandingImage } from "@app/utils/helpers/getBrandingImage";
import { useRouter } from "@app/utils/navigation";
import { EA_ERROR_CODES, PUBLIC_SETTINGS_KEYS } from "@ea/shared_types/next/ea.enums";
import { UserSchemas } from "@ea/shared_types/next/ea.schema";
import { UserTypes } from "@ea/shared_types/next/ea.types";
import {
  Box,
  FormInputsWrapper,
  FormPasswordTextField,
  FormSubmitButton,
  FormTextField,
  FormWrapper,
  NavigateNextOutlinedIcon,
  Paper,
  WarningOutlinedIcon,
} from "@ea/ui";
import { useOverlayStore } from "@ea/ui/components";
import { useGetPublicSettings } from "@ea/ui/hooks/api";
import { useNotification } from "@ea/ui/hooks/useNotification";
import { useTranslations } from "@ea/ui/translations";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import Image from "next/image";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ConfirmLoginDialog } from "./ConfirmLoginDialog";
import {
  LoginAlert,
  LoginDecoration,
  LoginFormContainer,
  LoginPageContainer,
  SSOLink,
} from "./LoginPage.styles";

export const LoginPage = () => {
  const { toast } = useNotification();
  const { open } = useOverlayStore();
  const router = useRouter();
  const [serverError, setServerError] = useState("");
  const queryClient = useQueryClient();

  const { data: publicSettings } = useGetPublicSettings();

  const enabled = useMemo(
    () => ({
      saml: publicSettings?.find((s) => s.id === PUBLIC_SETTINGS_KEYS.SSO_SAML_ENABLED)?.value,
      azure: publicSettings?.find((s) => s.id === PUBLIC_SETTINGS_KEYS.SSO_AZURE_ENABLED)?.value,
    }),
    [publicSettings],
  );

  const methods = useForm<UserTypes["login"]>({
    resolver: zodResolver(UserSchemas["login"]),
  });

  const { t } = useTranslations();

  const renderLogoEA = getBrandingImage({
    imageName: "LogoEA",
    textColor: "white",
    width: "100%",
    height: "auto",
  });

  const handleFormSubmit = async (data: UserTypes["login"]) => {
    const { password, username, withLogout } = data;
    try {
      const response = await login(username, password, withLogout);

      if (!response?.error) {
        router.push(ROUTES.dashboard.route());
        return;
      }

      if (response.error.code === EA_ERROR_CODES.USER_MULTI_SESSSION_BLOCK) {
        open(ConfirmLoginDialog, {
          onConfirm: () => {
            queryClient.clear();
            handleFormSubmit({ username, password, withLogout: true });
          },
        });
        return;
      }

      if (response.error.code === EA_ERROR_CODES.USER_ACCOUNT_IS_INACTIVE) {
        setServerError(t("Login.inactveUser"));
        return;
      }
      setServerError(t("Login.error"));
    } catch (error) {
      toast.error(t("Toast.somethingWentWrong"), { error });
    }
  };

  return (
    <LoginPageContainer>
      <Image src="/img/lp_bg_60.jpg" fill={true} alt="" style={{ zIndex: -1 }} />
      <LoginFormContainer maxWidth={"600px"}>
        <Box mb={2} width={"100%"}>
          {renderLogoEA}
        </Box>
        <Paper
          elevation={6}
          sx={{ paddingBottom: "2rem", borderRadius: "2rem", width: "75%", overflow: "hidden" }}
        >
          <LoginDecoration />
          <FormWrapper
            methods={methods}
            onSubmit={handleFormSubmit}
            dataTestid={DataTestIds.FORM_LOGIN}
          >
            <FormInputsWrapper px={"3rem"} gap={2}>
              <FormInputsWrapper alignItems="center">
                <FormTextField
                  dataTestid={DataTestIds.FORM_INPUT_USERNAME}
                  name="username"
                  label={t("Login.username")}
                  required
                />
                <FormPasswordTextField
                  name="password"
                  label={t("Login.password")}
                  dataTestid={DataTestIds.FORM_INPUT_PASSWORD}
                />

                {serverError && (
                  <LoginAlert>
                    <WarningOutlinedIcon />
                    {serverError}
                  </LoginAlert>
                )}
              </FormInputsWrapper>

              <FormSubmitButton endIcon={<NavigateNextOutlinedIcon />}>
                {t("Login.login")}
              </FormSubmitButton>

              <FormInputsWrapper direction={"row"} justifyContent="space-between">
                {enabled.azure && (
                  <SSOLink href={`${config.getApiPath()}/auth/microsoft`}>
                    {t("Login.sso.microsoft")}
                  </SSOLink>
                )}
                {enabled.saml && (
                  <SSOLink href={`${config.getApiPath()}/saml`}>{t("Login.sso.saml")}</SSOLink>
                )}
              </FormInputsWrapper>
            </FormInputsWrapper>
          </FormWrapper>
        </Paper>
      </LoginFormContainer>
    </LoginPageContainer>
  );
};
