"use client";
import {
  TranslateFunctionWithNamespace,
  TranslationWrapper,
  usePlatformsTranslations,
} from "@ea/ui/translations";
import merge from "lodash/merge";
import {
  AbstractIntlMessages,
  NextIntlClientProvider,
  useLocale,
  useTranslations as useNextIntlTranslations,
} from "next-intl";
import { ReactNode } from "react";

// NextIntlAdapter integrates next-intl with our custom TranslationWrapper.
// This allows our UI components to be integrated into applications with different translation setups.
export const NextIntlAdapter = ({
  children,
  messages,
}: {
  children: ReactNode;
  messages: AbstractIntlMessages;
}) => {
  const locale = useLocale().toUpperCase();

  const { translations: platformTranslations } = usePlatformsTranslations();

  const combinedMessages = {};
  merge(combinedMessages, messages);
  merge(combinedMessages, platformTranslations[locale]);

  //TODO: passing translations can be optimized in future, check docs for that
  return (
    <NextIntlClientProvider locale={locale} messages={combinedMessages}>
      <TranslationComponent>{children}</TranslationComponent>
    </NextIntlClientProvider>
  );
};

const TranslationComponent = ({ children }: { children: ReactNode }) => {
  const locale = useLocale().toUpperCase();
  const nextIntlT = useNextIntlTranslations();

  const translate: TranslateFunctionWithNamespace = (namespace) => {
    return (key, params) => {
      const fullKey = namespace ? `${namespace}.${String(key)}` : String(key);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return nextIntlT(fullKey as any, params as Record<string, string>);
    };
  };

  return (
    <TranslationWrapper
      translationContext={{
        locale,
        tWithNamespace: translate,
        changeLanguage: () => Promise.resolve(),
      }}
    >
      {children}
    </TranslationWrapper>
  );
};
