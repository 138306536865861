"use client";
import { ConfirmDialog, Typography } from "@ea/ui";
import { OverlayProps, useOverlayStore } from "@ea/ui/components";
import { useTranslations } from "@ea/ui/translations";

type PropTypes = {
  onConfirm: () => void;
};

export function ConfirmLoginDialog({ isOpen, onConfirm }: OverlayProps<PropTypes>) {
  const { t } = useTranslations("Login");
  const { close } = useOverlayStore();

  const closeDialog = async () => close(ConfirmLoginDialog);

  return (
    <ConfirmDialog
      type="warning"
      onClose={closeDialog}
      onConfirm={async () => {
        await closeDialog();
        onConfirm();
      }}
      open={isOpen || false}
      title={t("alert.title")}
      controls={{
        confirm: t("alert.confirm"),
        cancel: t("alert.cancel"),
      }}
    >
      <Typography>{t("alert.info")}</Typography>
    </ConfirmDialog>
  );
}
