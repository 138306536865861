import { styled } from "@mui/material";
import { Box, Link, Typography } from "@ea/ui";

export const SSOLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.secondary[500],
}));

export const LoginDecoration = styled(Box)(({ theme }) => ({
  height: "2rem",
  marginBottom: "2rem",
  background: `repeating-linear-gradient( -45deg, ${theme.palette.neutral[100]}, ${theme.palette.neutral[100]} 35px, #FFFFFF 35px, #FFFFFF 50px)`,
}));

export const LoginAlert = styled(Typography)(({ theme }) => ({
  color: theme.palette.error[500],
  display: "flex",
  gap: "1rem",
  lineHeight: "1rem",
  alignItems: "center",
}));

export const LoginPageContainer = styled(Box)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
}));

export const LoginFormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "100%",
  justifyContent: "center",
  flexDirection: "column",
  margin: "0 auto",
}));
