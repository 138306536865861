import type { LogoPropsType } from "@ea/ui";
import {
  LogoEA,
  LogoEA02,
  LogoEA03,
  LogoEE,
  LogoEE02,
  LogoEE03,
  LogoXplus,
  LogoXplus02,
  LogoXplusWhite,
} from "@ea/ui";

type BrandingImageName =
  | "LogoEA"
  | "LogoEA02"
  | "LogoEA03"
  | "LogoEE"
  | "LogoEE02"
  | "LogoEE03"
  | "LogoXplus"
  | "LogoXplus02"
  | "LogoXplusWhite";

type GetBrandingImageOptions = LogoPropsType & {
  imageName: BrandingImageName;
};

export const getBrandingImage = (options: GetBrandingImageOptions): JSX.Element => {
  const { imageName, ...props } = options;
  switch (imageName) {
    case "LogoEA03":
      return <LogoEA03 {...props} />;
    case "LogoEA":
      return <LogoEA {...props} />;
    case "LogoEA02":
      return <LogoEA02 {...props} />;
    case "LogoEE":
      return <LogoEE {...props} />;
    case "LogoEE02":
      return <LogoEE02 {...props} />;
    case "LogoEE03":
      return <LogoEE03 {...props} />;
    case "LogoXplus":
      return <LogoXplus {...props} />;
    case "LogoXplus02":
      return <LogoXplus02 {...props} />;
    case "LogoXplusWhite":
      return <LogoXplusWhite {...props} />;
    default:
      throw new Error(`No SVG found for image name: ${imageName}`);
  }
};
